<template>
	<LoadingScreen :loadingStatus="dataLoaded" :showLoader=true style="height: 100vh; margin: 0px"/>
	<div class="home">
		<WelcomePanel v-if="isFirstVisit" @closeClicked="setVisited()"/>
		<div class="container" style="display: flex;">
			<StationInfo />
			<div class="controls">
				<div class="topContainer" style="margin-bottom: 10px;">
					<Transition>
						<SearchOnMap
							v-show="SelectedStation.getInfo() == null" 
							@resultClicked="(l) => map.panToLocation(l)"
						/>
					</Transition>
				</div>
				<div class="centerContainer"></div>
			</div>
			<PollutionMap ref="map"/>
		</div>
	</div>
</template>

<script setup>
import PollutionMap from '@/components/PollutionMap.vue';
import StationInfo from '@/components/StationInfo.vue';
import SearchOnMap from '@/components/SearchOnMap.vue';
import WelcomePanel from '@/components/WelcomePanel.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'

import { ref, watch, onMounted } from 'vue'
import { useUrlSearchParams } from '@vueuse/core'
import { useRoute } from 'vue-router'

import { SelectedStation } from '@/state/SelectedStation.js';
import { StationsData } from '@/state/StationsData.js';

const map = ref(null)

const params = useUrlSearchParams('history')
const route = useRoute()

// Get station from URL
function setStationFromUrl() {
	// Get UUID
	const idFromUrl = route.query.station

	// Cancel if UUID not found in URL
	if (idFromUrl == null) return

	// Cancel if station already selected
	if (SelectedStation.station != null) return

	const unwatch = watch(StationsData, () => {
		// Wait to stations data load
		if (StationsData.stations == null) return
	
		// Get station with UUID from URL
		const station = StationsData.stations.find(e => e.id == idFromUrl)
	
		if (station != null) {
			console.log("Found URL param for station. Set station to: " + station.id)
			SelectedStation.set(station)
		}

		// Disable watch
		unwatch()
	})
}


const isFirstVisit = ref(false);

const checkFirstVisit = () => {
	const visited = localStorage.getItem('visited');
	console.log("Visited: " + visited)
	if (!visited) {
		isFirstVisit.value = true;	
	} 
};

const setVisited = () => {
	isFirstVisit.value = false;
	localStorage.setItem('visited', 'true');
}

// Show welcome message 
onMounted(() => {
	checkFirstVisit();
	setStationFromUrl();

	// Update URL
	watch(SelectedStation, () => {
		if (SelectedStation.station != null) {
			params.station = SelectedStation.station.id
		} else {
			params.station = null
		}
	})
})

const dataLoaded = ref(false)

// Hide loading when stations loaded
watch(StationsData, () => {
	if (StationsData.stations != null) {
		setTimeout(() => { dataLoaded.value = true }, 1000)
	}
})
</script>

<style scoped>
.home {
	/* To correctly size  */
	flex: 1 1 auto;
    width: 100%;
	overflow-y: auto;
}

.container {
	width: 100%;
	height: 100%;
	position: relative;
}

.controls {
	position: absolute;
	padding: 15px;
	z-index: 10;

	width: 100%;
	height: 100%;

	box-sizing: border-box;

	pointer-events: none;
}

.controls > .topContainer {
	width: 100%;

	display: flex;
	justify-content: center;
}

.controls > .topContainer > *{
	pointer-events: auto;
}

.controls > .centerContainer > *{
	pointer-events: auto;
}
.v-enter-active {
	transition: opacity 0.5s ease 1s,
				translate 0.5s ease-out 1s;
}
.v-leave-active {
	transition: opacity 0.5s ease,
				translate 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	translate: 0 -40px;
}
</style>