<template>
	<div class="contentContainer">
		<div class="content">
			<h1>Реєстрація кабінету</h1> 

			<div v-if="!secondStep">
				<p>Кабінет дозволяє вам керувати власними станціями моніторингу</p>

				<div>
					<small>Введіть ваше ім'я або назву організації</small>
					<input 
						type="text" 
						class="text-box" 
						:class="{ invalid: user.full_name.length > 0 && !isUserNameValid(user.full_name) }"
						v-model="user.full_name"
						placeholder="Ім'я / Назва організації" 
					>
				</div>
	
				<div style="padding-top: 0.5em;"> 
					<small>Введіть ваш e-mail</small><br/>
					<input 
						v-model="user.email" 
						type="email"
						placeholder="my.name@gmail.com" 
						class="text-box"
						:class="{ invalid: user.email.length > 0 && !isEmailValid(user.email) }"
					>
				</div>
	
				<div style="padding-top: 0.5em;">
					<small>Введіть пароль</small><br/>
					<input 
						v-model="user.password" 
						type="password" 
						:class="{ invalid: passwordsNotSame }"
						placeholder="Пароль" 
						class="text-box"
					>
				</div>
	
				<div style="padding-top: 0.5em;">
					<small>Підтвердження паролю</small><br/>
					<input 
						v-model="user.password_confirmation" 
						type="password" 
						:class="{ invalid: passwordsNotSame }"
						placeholder="Підтвердження паролю" 
						class="text-box"
					>
				</div>
	
				<div style="padding-top: 0.5em;">
					<small>Номер телефону</small><br/>
					<input 
						v-model="user.phone" 
						type="tel" 
						placeholder="+380XX XXX XXXX" 
						class="text-box"
						:class="{ invalid: user.phone.length > 0 && !isPhoneValid(user.phone) }"
					>
				</div>

				<div style="padding-top: 1em;">
					<label class="checkbox">
						<input type="checkbox" v-model="acceptTerms"/>
						Я погоджуюсь з&nbsp;
						<a href="https://eco-city.org.ua/terms_and_conditions" target="_blank">
							політикою конфіденційності
						</a>
					</label>
				</div>
				
				<div style="padding-top: 1.5em; width: 100%;">
					<button 
						class="btn" 
						@click="registerClick()"
					>
						Зареєструватись
						<FontAwesomeIcon :icon="faRightToBracket"/>
					</button>
				</div>
			</div>

			<div v-else>
				<p>На вашу електронну пошту був надісланий лист з підтвердженням реєстрації.</p>
				<p>Обов'язково перевірте папку "Спам".</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'

import { isEmailValid, isPhoneValid, isUserNameValid } from '@/modules/DataValidation'
import { User } from '@/state/User'

const secondStep = ref(false)

const acceptTerms = ref(false)

const user = ref({
	email: "",
	full_name: "",
	password: "",
	password_confirmation: "",
	phone: ""
})

const passwordsNotSame = computed(() => {
	const pass = user.value.password
	const pass_confirm = user.value.password_confirmation
	return (pass.length > 0 && pass_confirm.length > 0) && pass != pass_confirm
})

async function registerClick() {
	const u = user.value

	if (
		!isEmailValid(u.email) ||  
		(u.phone.length > 0 && !isPhoneValid(u.phone))
	) {
		window.alert("Перевірте правильність введених даних!")
		return
	}

	if (u.password.length < 8) {
		window.alert("Мінімальна довжина паролю - 8 символів!")
		return
	}

	if (acceptTerms.value == false) {
		window.alert("Вам необхідно погодитись з політикою конфіденційності!")
		return
	}

	if (await User.register(u)) {
		secondStep.value = true
	} else {
		window.alert("Щось пішло не так :(\nПеревірте правильність введених даних або повідомте розробників про помилку.")
	}

}
</script>

<style scoped>
.content {
	max-width: 24em;
}

.btn, .text-box {
	font-size: medium;
	/* border-radius: 8px; */
}

.text-box.invalid {
	background-color: #7c2f2f;
}

</style>