<template>
	<div class="calendarContainer" ref="calendarContainer">
		<table class="table">
			<thead class="tableHeader">
				<th
					v-for="e in calendarData.headerRow"
					:key="e"
				>
					<span>{{ e.text }}</span>
				</th>
			</thead>
			<tr 
				v-for="weekDays in calendarData.dataTable"
				:key=weekDays
				class="tableRow"
			>
				<td 
					v-for="d in weekDays"
					:key=d
					:class="{ rowHeader: d.rowName != null }"
				>
					<span v-if="d.rowName != null">
						{{ d.rowName }}
					</span>
					<div 
						v-else-if="d.value != null" 
						:style="{ backgroundColor: uaqiCalendarColors[getValueByType(d.value)] }"
					>
						<!-- {{ d.value.main }} -->
						<!-- <span style="font-size: xx-small;">{{ d.date.substring(2, 10) }}</span> -->
					</div>
					<div v-else></div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script setup>

import { ref, onMounted, defineProps, computed } from 'vue'

import { uaqiColors } from '@/modules/UAQI.js'

const uaqiCalendarColors = [ "#151515" ].concat(uaqiColors.slice(1))

const props = defineProps({
	data: { type: Object },
	type: { type: String, required: true }
})

function getValueByType(uaqi) {
	if (props.type == "main") 
		return uaqi.main
	else 
		return uaqi.categories[props.type]
}

function sortData(data) {
	let entries = Object.entries(data)
	entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

	let sortedData = {}
	for (let [key, value] of entries) sortedData[key] = value

	return sortedData
}

function adjustDataToStartFromMonday(data) {
	const dates = Object.keys(data).map(date => new Date(date));
	const firstDate = dates[0];
	const firstDayOfWeek = ((firstDate.getDay() + 6) % 7);

	const result = {};

	for (let i = firstDayOfWeek; i > 0; i--) {
		const newDate = new Date(firstDate);
		newDate.setDate(newDate.getDate() - i);
		const formattedDate = newDate.toISOString().split('T')[0];
		result[formattedDate] = null;
	}

	Object.keys(data).forEach(date => {
		result[date] = data[date];
	});

	return result;
}

function adjustDataToHave365days(data) {
	const dates = Object.keys(data).map(date => new Date(date))
	const firstDate = dates[0]

	let startDate = new Date(firstDate)
	startDate.setDate(startDate.getDate() - (365 - dates.length))

	// Result object
	const result = {};

	// Date iterator
	let currentDate = new Date(startDate);
	currentDate.setHours(12, 0, 0)

	while (currentDate <= firstDate) {
		const formattedDate = currentDate.toISOString().split('T')[0];
		result[formattedDate] = null;

		// Increment date
		currentDate.setDate(currentDate.getDate() + 1);
		currentDate.setHours(12, 0, 0)
	}

	// Create result object
	Object.keys(data).forEach(date => {
		result[date] = data[date];
	});

	return result;
}

function generateCalendarData(data) {

	const dayNames = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"];
	
	const dataTable = dayNames.map(day => [{ "rowName": day.toLowerCase() }]);
	
	for (const [date, value] of Object.entries(data)) {
		const dayIndex = ((new Date(date).getDay() + 6) % 7);
		dataTable[dayIndex].push({ "value": value, "date": date });
	}

	return dataTable;
}

function generateHeaderForDataTable(dataTable) {
	const firstRow = dataTable[0]

	let headerRow = [ ]

	const monthNames = ["Січ", "Лют", "Бер", "Квіт", "Трав", "Черв",
		"Лип", "Серп", "Вер", "Жовт", "Лист", "Груд"
	];

	const firstDate = new Date(firstRow[1].date)
	console.log("firstDate", firstDate)

	firstRow.forEach(e => {
		if (e.date == null) {
			// headerRow.push({ text: "D" })
			headerRow.push({ text: "" })
			return
		}

		function sameMonth(date1, date2) {
			if (date1 == null || date2 == null) return false
			return date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear()
		}

		const date = new Date(e.date)

		if (sameMonth(date, firstDate) && date.getDate() > 14) {
			// headerRow.push({ text: "S" })
			headerRow.push({ text: "" })
			return
		} 

		const monthName = monthNames[date.getMonth()]

		if (headerRow.find(e => sameMonth(e.date, date)) == null) {
			headerRow.push({ date: new Date(e.date), text: `${monthName}` })
		} else {
			// headerRow.push({ text: "A" })
			headerRow.push({ text: "" })
		}
	})

	return headerRow
}


// Generate calendar data
const calendarData = computed(() => {
	let defaultData = {}
	defaultData[new Date().toISOString().split('T')[0]] = null

	let data = props.data ?? defaultData

	data = sortData(data)

	data = adjustDataToHave365days(data)

	data = sortData(data)
	data = adjustDataToStartFromMonday(data)

	const dataTable = generateCalendarData(data)
	const headerRow = generateHeaderForDataTable(dataTable)

	return { dataTable, headerRow }
})

// To scroll to end by default 
const calendarContainer = ref(null);

const scrollToEnd = () => {
	const container = calendarContainer.value;
	container.scrollLeft = container.scrollWidth;
};

onMounted(() => {
	scrollToEnd()
})
</script>

<style scoped>
.calendarContainer {
	/* width: 100%; */

	user-select: none;

	overflow-x: auto;

	box-sizing: border-box;
}

.tableHeader > th {
	overflow: visible;
	max-width: 1.2em;
	width: 1.2em;
	text-align: left;
}

.tableHeader > th > span {
	font-size: small;
	font-family: monospace;
}

.rowHeader {
	position: absolute;

	font-size: small;
	padding: 0;
}

.table {
	font-size: 10.5px;
}

.tableRow > td > div {
	width: 1.2em;
	height: 1.2em;

	background-color: var(--color-paper);

	border-radius: 3px;
}

table, th, td {
	border: 0px solid white;
	border-collapse: collapse;
}

td {
	padding: 2px;
}
</style>